var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "CRow",
            [
              _vm.errorMessage
                ? _c(
                    "CCol",
                    { attrs: { md: "12" } },
                    [
                      _c("CAlert", { attrs: { color: "warning" } }, [
                        _vm._v("Fehler: " + _vm._s(_vm.errorMessage))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "CCol",
                { attrs: { md: "8" } },
                [
                  _c(
                    "CCard",
                    [
                      _c(
                        "CCardBody",
                        [
                          _c("h1", [
                            _vm._v(_vm._s(_vm.templateText.templateTextType))
                          ]),
                          _c("ValidatedSelect", {
                            attrs: {
                              translationKey: "templateText.textType",
                              field: _vm.$v.templateText.textType,
                              value: _vm.$v.templateText.textType.$model,
                              options: _vm.textTypeOptions
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.templateText.textType,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("ValidatedInput", {
                            attrs: {
                              translationKey: "templateText.subjectDe",
                              field: _vm.$v.templateText.subjectDe,
                              value: _vm.$v.templateText.subjectDe.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.templateText.subjectDe,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "pages.admin.AdminTemplateTextEdit.defaultSubject"
                                  )
                                ) +
                                ": " +
                                _vm._s(_vm.templateText.defaultSubjectDe) +
                                " "
                            )
                          ]),
                          _c("br"),
                          _c("ValidatedTextArea", {
                            attrs: {
                              translationKey: "templateText.textDe",
                              field: _vm.$v.templateText.textDe,
                              rows: "10",
                              value: _vm.$v.templateText.textDe.$model
                            },
                            on: {
                              "update:value": function($event) {
                                return _vm.$set(
                                  _vm.$v.templateText.textDe,
                                  "$model",
                                  $event
                                )
                              }
                            }
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "pages.admin.AdminTemplateTextEdit.defaultText"
                                )
                              ) +
                                ": " +
                                _vm._s(_vm.templateText.defaultTextDe)
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "CCol",
                    { staticClass: "ml-auto", attrs: { md: "12" } },
                    [
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "forms.common.saveAndClose",
                            expression: "'forms.common.saveAndClose'"
                          }
                        ],
                        attrs: { color: "primary" },
                        on: { click: _vm.handleSubmit }
                      }),
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value: "forms.common.cancel",
                            expression: "'forms.common.cancel'"
                          }
                        ],
                        attrs: { color: "secondary" },
                        on: { click: _vm.handleCancel }
                      }),
                      _c("CButton", {
                        directives: [
                          {
                            name: "t",
                            rawName: "v-t",
                            value:
                              "pages.admin.AdminTemplateTextEdit.showPreview",
                            expression:
                              "'pages.admin.AdminTemplateTextEdit.showPreview'"
                          }
                        ],
                        attrs: { color: "secondary" },
                        on: { click: _vm.handlePreview }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "CCol",
                { attrs: { md: "4" } },
                [
                  _c("HelpCard", {
                    attrs: {
                      focusFor: _vm.focusFor,
                      name: "pages.admin.AdminTemplateTextIndex.info.box1",
                      textVariables: { variableName: "{{order.PlayroomName}}" }
                    }
                  }),
                  _c("HelpCard", {
                    attrs: {
                      focusFor: _vm.focusFor,
                      name: "pages.admin.AdminTemplateTextIndex.info.box2",
                      textVariables: _vm.templateText.exampleVariables
                    }
                  }),
                  _c("HelpCard", {
                    attrs: {
                      focusFor: _vm.focusFor,
                      name: "pages.admin.AdminTemplateTextIndex.info.box3",
                      textVariables: _vm.templateText.exampleVariables
                    }
                  }),
                  _c("HelpCard", {
                    attrs: {
                      focusFor: _vm.focusFor,
                      name: "pages.admin.AdminTemplateTextIndex.info.box4",
                      textVariables: _vm.templateText.exampleVariables
                    }
                  }),
                  _c("HelpCard", {
                    attrs: {
                      focusFor: _vm.focusFor,
                      name: "pages.admin.AdminTemplateTextIndex.info.box5",
                      textVariables: _vm.templateText.exampleVariables
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "CModal",
        {
          attrs: {
            title: _vm.$t("pages.admin.AdminTemplateTextEdit.previewTitle"),
            show: _vm.showModal,
            centered: true,
            closeOnBackdrop: true
          },
          on: {
            "update:show": function($event) {
              _vm.showModal = false
            }
          }
        },
        [
          _c("div", [
            _c("b", [
              _c("span", {
                domProps: { innerHTML: _vm._s(_vm.previewSubjectDe) }
              })
            ]),
            _c("br"),
            _c("br"),
            _c("span", { domProps: { innerHTML: _vm._s(_vm.previewTextDe) } })
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }